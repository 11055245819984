<script lang="ts">
import {defineComponent} from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import { ref } from 'vue';

import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import { FreeMode, Thumbs } from 'swiper/modules';
import GameEnvItems from "@/components/GameEnvItems";
import {getImgUrl} from "@/helper";

export default defineComponent({
  name: "EnvironmentGame",
  methods: {getImgUrl},
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const thumbsSwiper = ref(null);

    const setThumbsSwiper = (swiper:any) => {
      thumbsSwiper.value = swiper;
    };

    return {
      items:GameEnvItems,
      thumbsSwiper,
      setThumbsSwiper,
      modules: [FreeMode, Thumbs],
    };
  },
})
</script>

<template>
 <div class="container" id="EnvironmentGame">
   <div class="page-titles page-title">Game Environment</div>
   <div >
     <swiper
         id="swiper_slider"
         :spaceBetween="10"
         :thumbs="{ swiper: thumbsSwiper }"
         :modules="modules"
         class="main-slider"
         :loop="true"
     >
       <swiper-slide v-for="(item,index) in items" :key="index">
         <img :src="require(`@/assets/images/game-env/${item.img}`)" :alt="item.title"/>
       </swiper-slide>
     </swiper>
     <swiper
         :loop="true"
         @swiper="setThumbsSwiper"
         :spaceBetween="20"
         :slidesPerView="3.5"
         :freeMode="true"
         :centered-slides="true"
         :watchSlidesProgress="true"
         :modules="modules"
         class="mySwiper swiper-thumb2"
     >
       <swiper-slide v-for="(item,index) in items" :key="index">
         <img :src="require(`@/assets/images/game-env/${item.img}`)" :alt="item.title" />
         <div class="bottom-content">
           <div class="title">{{ item.title }}</div>
           <div class="content">{{ item.description }}</div>
         </div>
       </swiper-slide>

     </swiper>
   </div>
 </div>
</template>

<style scoped lang="css">
.bottom-content
{
  background: rgba(23, 1, 65, 0.51);
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 30%;
  color: white;
  padding: 10px;
}
.bottom-content .title
{
  font-weight: bold;
}
.bottom-content .content
{
  font-weight: lighter;
  font-size: 13px;
}
.page-title
{
  text-align: center;
  margin-top: 4%;
  margin-bottom: 4%;
}

.main-slider
{
  width: 100%;
  aspect-ratio: 80/45;
  border-radius: 20px;
}
.mySwiper {
  width: 100%;
  aspect-ratio: 80/12;
  overflow: visible;
  margin-top: -7%;
  position: relative;
  z-index: 2;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  filter: opacity(1);
  transition: all 400ms;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.mySwiper .swiper-slide-thumb-active {
  filter: opacity(1);
  transform: scale(1.1);
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


</style>