<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Footer"
})
</script>

<template>
  <div class="wrapper-footer px-5" id="footerPart">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="social-items">
          <div class="title title1 ">follow us</div>
          <div class="icons">
            <a class="icon icon1 " target="_blank" href="https://x.com/DecentraG?s=35">
              <img src="@/assets/images/x.png" alt="">
            </a>
            <a class="icon icon2 " target="_blank" href="https://t.me/decentraguys">
              <img src="@/assets/images/telegram.png" alt="">
            </a>
            <a class="icon icon2 " target="_blank" href="https://discord.gg/y6PWaQ8Nxq">
              <img src="@/assets/images/discord.png" alt="">
            </a>
            <a class="icon icon2 d-none" target="_blank" href="">
              <img src="@/assets/images/facebook.png" alt="">
            </a>
            <a class="icon icon3 d-none">
              <img src="@/assets/images/linkedin.png" alt="">
            </a>
            <a class="icon icon4 d-none" target="_blank" href="">
              <img src="@/assets/images/insta.png" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 ms-auto">
        <div class="row d-none">
          <div class="col-6 col-md-4 mb-3 menu1 ">
            <div class="list-title">
              Shop
            </div>
            <ul class="menu-list">
              <li>Monkey NFTS</li>
              <li>Monkey NFTS</li>
              <li>Monkey NFTS</li>
            </ul>
          </div>
          <div class="col-6 col-md-4 mb-3 menu2 ">
            <div class="list-title">
              Shop
            </div>
            <ul class="menu-list ">
              <li>Monkey NFTS</li>
              <li>Monkey NFTS</li>
              <li>Monkey NFTS</li>
            </ul>
          </div>
          <div class="col-6 col-md-4 mb-3 menu3 ">
            <div class="list-title">
              Shop
            </div>
            <ul class="menu-list">
              <li>Monkey NFTS</li>
              <li>Monkey NFTS</li>
              <li>Monkey NFTS</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr/>
  </div>
</template>

<style scoped lang="css">
.container
{
  margin-bottom: 5%;
}
.social-items {
  width: max-content;
  text-align: center;
  font-size: 1.2vw;
}
.social-items .icons
{
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
}
.social-items .icon
{
  flex: 0 0 40px;
  margin-top: 10px;
}
.social-items .icon img
{
  width: 100%;
}
.list-title
{
  font-size: 1.1vw;
}
.menu-list
{
  list-style: none;
  padding: 0;
  padding-left: 5px;
  padding-top: 5px;
}
.menu-list li
{
  padding-bottom: 5px;
}
hr {
  color: inherit;
  border: 0;
  border-top: white solid;
  opacity: 1;
  margin-top: 2%;
}
.mobile .social-items {
  width: max-content;
  text-align: center;
  font-size: 5.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  margin-bottom: 15px;
}
.mobile .list-title {
  font-size: 5.1vw;
  /* font-weight: 800; */
}
.mobile .social-items .icons {
  justify-content: space-evenly;
}
.mobile .social-items .icon
{
  flex: 0 0 30px;
}

</style>