import Web3 from "web3";
import {Web3Helper} from "@/blockchain/web3-helper";
import * as net from "net";
import {waitForPageLoad} from "@/helper";
import * as process from "process";

const state = () => ({
  pageLoaded: false,
  isWeb3Available: false,
  isWeb3Injected: false,
  networkId: null,
  isConnectedToRightNetwork: false,
  currentAccount: null,
  referrer: <string | null>null,
  platform: <string>'desktop',
  web3Loading: false
});

const mutations = {
  // setReferrer(state, referrer)
  // {
  //   console.log('aallii '+referrer)
  //   state.referrer = referrer;
  // }
  setPlatform(state, platform)
  {
    state.platform = platform
  },
};

const actions = {
  async initiateState({state})
  {
    state.web3Loading = true;

    try
    {
      if (!state.pageLoaded)
      {
        console.log('page is loading')
        await waitForPageLoad();
        console.log('page loaded')
        state.pageLoaded = true;
      }
      if (Web3Helper.isWeb3Available())
      {
        console.log('web3 available')
        state.isWeb3Available = true;
        state.isWeb3Injected = await Web3Helper.isWeb3Injected();
        console.log(state.isWeb3Injected)
        if (state.isWeb3Injected)
        {
          console.log('web3 injected')
          state.isWeb3Available = true;
          const networkId = process.env.VUE_APP_NETWORK_ID;
          // const networkId = '5777';
          // const currentNetworkId = await Web3Helper.getNetworkId();

          const currentNetworkId = networkId
          console.log(networkId, currentNetworkId)
          if (currentNetworkId)
          {
            console.log()
            console.log(currentNetworkId)
            const accounts = await Web3Helper.getAccounts();
            if (accounts && accounts.length)
              state.currentAccount = accounts[0];
            else
              state.currentAccount = null;

            state.networkId = currentNetworkId;
            if (currentNetworkId === networkId)
            {
              state.isConnectedToRightNetwork = true;
            }
            else
            {
              state.currentAccount = null;
            }
          }
          else
          {
            state.currentAccount = null;
            state.isConnectedToRightNetwork = false;
          }
        }
        else
        {
          state.currentAccount = null;
          state.isConnectedToRightNetwork = false;
        }
      }
      else
      {
        state.currentAccount = null;
        state.isConnectedToRightNetwork = false;
      }
    }
    catch (e)
    {
      console.log(e)
    }

    state.web3Loading = false;
  },
  setPlatform({commit}, platform)
  {
    commit('setPlatform', platform)
  }
  // setReferrer({commit},referrer)
  // {
  //   commit('setReferrer',referrer)
  // }
};

const getters = {
  platform(state)
  {
    return state.platform;
  },
  referrer(state)
  {
    console.log('ggg')
    return state.referrer
  },
  isWeb3Available(state)
  {
    return state.isWeb3Available
  },
  isWeb3Injected(state)
  {
    return state.isWeb3Injected;
  },
  getCurrentAccount(state)
  {
    return state.currentAccount;
  },
  getCurrentNetworkId(state)
  {
    return state.networkId
  },
  isConnectedToRightNetwork(state)
  {
    return state.isConnectedToRightNetwork;
  },
  getWeb3Loading(state)
  {
    return state.web3Loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
