<script lang="ts">
import {defineComponent} from "vue";
import {Web3Helper} from "@/blockchain/web3-helper";
import {closeModal} from "@/helper";

export default defineComponent({
  name:"Web3Modals",
  methods:{
    closeModal(id)
    {
      closeModal(id)
    },
    connectToProperNetwork()
    {
      Web3Helper.connectToPolygon();
    },
  }
})
</script>

<template>

  <div class="modal fade" ref="installMetaMaskModal" id="installMetaMaskModal" tabindex="-1" role="dialog"
       aria-labelledby="installMetaMaskModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="installMetaMaskModalLabel">Need Web3 Wallet</h5>
          <button type="button" class="close" data-dismiss="modal" @click="closeModal('installMetaMaskModal')" aria-label="Close">
            <span aria-hidden="true" class="text-white">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            Hi there. we are so proud that you are interested in BluckyChain. but to use our service, you should have
            connect
            a wallet to our website. we recommend
            MetaMask or Coinbase for phone.
          </p>
          <div class="video-container">
<!--            <iframe width="880" height="495" src="https://www.youtube.com/embed/cn8gKxUSquo" title="How to Install Metamask Wallet in Chrome | Metamask Wallet" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>-->
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal('installMetaMaskModal')" data-dismiss="modal">Ok, I got it</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade fancy" id="wrongNetworkModal" tabindex="-1" role="dialog"
       aria-labelledby="wrongNetworkModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="wrongNetworkModalLabel">Need Web3 Wallet</h5>
          <button type="button" class="close" data-dismiss="modal" @click="closeModal('wrongNetworkModal')" aria-label="Close">
            <span aria-hidden="true" class="text-white">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          You are not connected to the proper network, click button below to connect to the right network.<br/>
          <div class="center">
            <button type="button" class="btn btn-black" @click="connectToProperNetwork">Connect to Polygon</button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal('wrongNetworkModal')" data-dismiss="modal">Ok, I got it</button>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="css">
.modal .close {
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: #160040;
}
.modal-content {
  background: #231053;
}
.btn-black {
  background: #000;
  color: #fff;
}
</style>