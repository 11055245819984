<script lang="ts">
import {defineComponent} from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { EffectCoverflow, Pagination } from 'swiper/modules';


export default defineComponent({
  name: "OurProject",
  methods:{
    getImageSrc(number) {
      return require(`@/assets/images/characters/character_${number}.webp`);
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
})
</script>

<template>
 <div class="page" id="ourProjectPart">
   <div class="container">
     <div class="row">
       <div class="col-12 col-md-5 me-auto mb-4 left-part animate__animated">
         <div class="page-titles">Our Project</div>
         <div class="content">
           We aim to create the perfect multiplayer Metaverse experience with the power of AI and VR.
           The perfect place for everyone to enjoy and compete for the BIG prize!
         </div>
       </div>
       <div class="col-12 col-md-4 ms-auto mb-4 right-part animate__animated">
         <div class="members-count">
           <div class="products">
             <div class="count">+10K</div>
             <div class="count-title">Products</div>
           </div>
           <div class="players">
             <div class="count">+100K</div>
             <div class="count-title">Player</div>
           </div>
           <div class="artist">
             <div class="count">+20K</div>
             <div class="count-title">Artist</div>
           </div>
         </div>
       </div>
     </div>
     <div class="slider">
       <swiper
           :effect="'coverflow'"
           :grabCursor="true"
           :centeredSlides="true"
           :slidesPerView="'auto'"
           :loop="true"
           :centered-slides="true"
           :coverflowEffect="{
      rotate: 0,
      stretch: 20,
      depth: 150,
      modifier: 3,
      slideShadows: true,
    }"
           :modules="modules"
           class="mySwiper our-project-slider"
       >
         <swiper-slide v-for="number in 15" :key="number">
           <img :src="getImageSrc(number)" alt=""/>
         </swiper-slide>
       </swiper>
     </div>
   </div>
 </div>
</template>

<style scoped lang="css">
.page
{
  margin: 5% 0;
}
.title
{
  font-size: 4vw;
  font-weight: bold;
  font-family: GoodTiming;
}
.products {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.players {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.artist {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}
.members-count .count {
  font-weight: 1000;
  font-size: 1.4vw;
}
.members-count
{
  text-align: center;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  height: 500px;
  display: flex;
  width: 284.25px;
  padding: 24.75px 7.5px 0px 8.25px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 150px;
  background: #D9D9D9;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mobile .members-count .count {
  font-size: 6.4vw;
}
.mobile .swiper-slide {
  height: 350px;
  width: 187px;
  padding: 18px;
}
</style>