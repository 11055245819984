<script lang="ts">
import {defineComponent} from 'vue'
import {Web3Helper} from "@/blockchain/web3-helper";
import {getNumbersFromString, openModal, sleep} from "@/helper";
import Web3 from "web3";
import {TokenContractApi} from "@/blockchain/token-contract.api";
import {mapGetters} from "vuex";
import Cookies from "js-cookie";
import referrer from "../views/Referrer.vue";

export default defineComponent({
  name: "PurchaseForm",
  data()
  {
    return {
      referrer:<string | null>null,
      canBuy: false,
      successPurchase: false,
      buyLoading:false,
      errorText: '',
      currentBalance: 0,
      copyBtnText: 'copy',
      maticAmount: 0,
      dgtAmount: 0,
      modalVisible: false,
      tokenApi: <TokenContractApi><unknown>null,
      itoTotalAmount: 30000000000,
      itoDetails: {
        itoIsActive: false,
        itoPercents: <number[]>[],
        itoTotalAmount: <number[]>[],
        itoRates: [1000, 800, 500, 400, 200],
        itoRemainingAmounts: <number[]>[],
        itoSoldAmounts: <number[]>[],
        itoSoldPercents: <number[]>[],
        itoCurrentLevel: 1
      },
      currentDgtBalance:0
    }
  },
  mounted()
  {
    const ref = Cookies.get('referrer');
    if (ref)
      this.referrer = ref;
    this.initiate();

    // @ts-ignore
    this.emitter.on('web3Changed', (data) => {
      this.initiate().then(()=>
      {
        if (this.dgtAmount)
        {
          this.checkCanBuy();
        }
      });
    });
  },
  methods: {
    async updateDgt()
    {
      const web3 = new Web3(Web3Helper.getWindowWeb3());
      const tokenApi = new TokenContractApi(web3);

      const b = await tokenApi.balanceOf(await tokenApi.getCurrentAccount());

      this.currentDgtBalance = b;
    },
    async transfer()
    {
      const web3 = new Web3(Web3Helper.getWindowWeb3());
      const tokenApi = new TokenContractApi(web3);

      await tokenApi.transfer('0x658e3f82EaAe34e6cE851AEf0D7e4032329b41B4','1000000000');
    },
    async buy()
    {
      try
      {
        this.canBuy = false;
        this.buyLoading = false;

        let r;
        console.log('Ref: ',this.referrer)
        if (this.referrer)
          r = await this.tokenApi.buyWithReferrer(this.referrer, this.maticAmount);
        else
          r = await this.tokenApi.buy(this.maticAmount);
        if (r)
        {
          //@ts-ignore
          this.$swal('Purchase successfully completed');
          this.buyLoading = false;
          this.canBuy = false;
          this.dgtAmount = 0;
        }
      }
      catch (e:any)
      {
        this.errorText = 'Transaction Error. '+((e && e.message)?'Error Details: '+e.message:'');
        console.log(e)
      }
    },
    waitForWeb3ToLoad()
    {
      return new Promise(async (resolve, reject) =>
      {
        await sleep(300);
        const interval = setInterval(() =>
        {
          if (!this.web3IsLoading)
          {
            clearInterval(interval);
            resolve('Variable is true!');
          }
        }, 100); // Check every 100ms (adjust as needed)
      });
    },
    async initiate(retry = 0)
    {

      this.waitForWeb3ToLoad().then(async () =>
      {
        if (this.isWeb3Available && this.isWeb3Injected && this.isConnectedToRightNetwork && this.isConnectedToRightNetwork)
        {
          const web3 = new Web3(Web3Helper.getWindowWeb3());
          this.tokenApi = new TokenContractApi(web3);
          const b = Number(await Web3Helper.getNativeBalance(web3, this.currentAccount));
          if (!isNaN(b))
            this.currentBalance = Math.round(b / 10 ** 14) / 1000;
        }
        else if (retry < 10)
          await this.initiate()
      });
    },
    enableWeb3()
    {
      Web3Helper.enableWeb3()
    },
    async getItoDetails()
    {
      const info = await this.tokenApi.getItoDetails();
      if (info)
      {
        const data = {
          itoIsActive: info.itoIsActive,
          itoPercents: info.itoPercents,
          itoTotalAmount: <number[]>[],
          itoRates: info.itoRates,
          itoRemainingAmounts: info.itoRemainingAmounts,
          itoSoldAmounts: <number[]>[],
          itoSoldPercents: <number[]>[],
          itoCurrentLevel: info.itoCurrentLevel
        }
        for (let i = 0; i < info.itoPercents.length; i++)
        {
          data.itoPercents[i] = Number(info.itoPercents[i]);
          data.itoRemainingAmounts[i] = Number(info.itoRemainingAmounts[i]) / (10 ** 8);
          data.itoTotalAmount[i] = this.itoTotalAmount * data.itoPercents[i] / 100;
          data.itoSoldAmounts[i] = data.itoTotalAmount[i] - data.itoRemainingAmounts[i];
          data.itoSoldPercents[i] = Math.round(data.itoSoldAmounts[i] / this.itoTotalAmount * 10000) / 100;
        }
        this.itoDetails = data;
      }
    },
    copyText(url: string)
    {
      navigator.clipboard.writeText(url);
      this.copyBtnText = 'copied ✓';
      setTimeout(() =>
      {
        this.copyBtnText = 'copy';
      }, 4000);
    },
    openModal(id)
    {
      openModal(id)
    },
    checkCanBuy()
    {
      if (this.dgtAmount<100)
      {
        this.canBuy = false;
        this.errorText = 'minimum DGT amount should be 100 DGTs';
      }
      else if (this.maticAmount>this.currentBalance)
      {
        this.canBuy = false;
        this.errorText = 'insufficient POL balance. current balance is: '+this.currentBalance;
      }
      else
      {
        this.canBuy = true;
        this.errorText = '';
      }
    }
  },
  computed: {
    ...mapGetters('web3', {
      isWeb3Available: 'isWeb3Available',
      isWeb3Injected: 'isWeb3Injected',
      currentAccount: 'getCurrentAccount',
      currentNetworkId: 'getCurrentNetworkId',
      web3IsLoading: 'getWeb3Loading',
      isConnectedToRightNetwork: 'isConnectedToRightNetwork',
    })
  },
  watch: {
    dgtAmount()
    {
      const rate = this.itoDetails.itoRates[this.itoDetails.itoCurrentLevel - 1];
      if (isNaN(Number(this.dgtAmount)))
      {
        this.dgtAmount = getNumbersFromString(this.dgtAmount);
        return;
      }
      this.maticAmount = this.dgtAmount / rate;

      this.checkCanBuy();
    },
    maticAmount()
    {
      const rate = this.itoDetails.itoRates[this.itoDetails.itoCurrentLevel - 1];
      if (isNaN(Number(this.maticAmount)))
      {
        this.maticAmount = getNumbersFromString(this.maticAmount);
        return;
      }
      this.dgtAmount = this.maticAmount * rate;

      this.checkCanBuy();
    }
  }
})
</script>

<template>
  <div class="connect-div">
    <div class="top-items">
      <div class="left-top">
        <span>POL</span>
        <img class="change-img" src="@/assets/images/arrow-2.svg" alt="">
        <span>DGT</span>
      </div>
      <div class="right-top">
        1 POL = {{ itoDetails.itoRates[itoDetails.itoCurrentLevel - 1] }} DGT
      </div>
    </div>
    <div class="input-div">
      <div class="input-title">
        <div>POL amount <img src="@/assets/images/bitcoin.webp" alt="" class="input-label-img"></div>
        <!--        <div>24000 ETH</div>-->
      </div>
      <input type="text" v-model="maticAmount" class="form-control">
    </div>
    <div class="gap-div">
      <img src="@/assets/images/input-change.webp" alt="" class="gap-pic"/>
    </div>

    <div class="input-div input-div2">
      <div class="input-title">
<!--        <div>DGT Balance: {{currentDgtBalance}}</div>-->
<!--        <button @click="updateDgt">Do</button>-->
<!--        <button @click="transfer">Transfer</button>-->
        <div>DGT amount <img src="@/assets/images/dgt.webp" alt="" class="input-label-img"></div>
        <!--        <div>34 BNB</div>-->
      </div>
      <input type="text" v-model="dgtAmount" class="form-control" >
    </div>
    <div class="content">
      <div class="title2">Refral freiend</div>
      <div class="page-contents">
        Come and grab this referral link and introduce your friends, and get 5 percent for their purchase.
      </div>
    </div>
    <div class="link-div">
      <div class="link-wrapper" v-if="isWeb3Available && isConnectedToRightNetwork && isWeb3Injected">
        <div class="link" id="link">https://decentraguys.com/ref/{{ currentAccount }}</div>
        <div class="copy" @click="copyText('https://decentraguys.com/ref/'+currentAccount)" v-html="copyBtnText"></div>
      </div>
      <div v-else class="text-danger">
        please connect your wallet to get your referral link
      </div>
    </div>
    <div class="d-grid gap-2 mt-5">
      <button v-if="!isWeb3Available" type="button" class="btn btn-gradient btn-lg"
              @click="openModal('installMetaMaskModal')">Connect Wallet
      </button>
      <button v-else-if="!isWeb3Injected" type="button" class="btn btn-gradient" @click="enableWeb3">Connect
        Wallet
      </button>
      <button v-else-if="!isConnectedToRightNetwork" type="button" class="btn btn-gradient btn-lg"
              @click="openModal('wrongNetworkModal')">
        Wrong Network
      </button>
      <div v-else class="w-100">
        <div class="text-danger error-text">{{ errorText }}</div>
        <button class="btn btn-gradient btn-lg w-100" :disabled="!canBuy || buyLoading" @click="buy">{{ buyLoading?'Please wait ...':'Buy' }}</button>
        <div class="text-success" v-if="successPurchase">Purchase Completed.</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.connect-div {
  border-radius: 12px;
  background: #1D0054;
  display: block;
  padding: 25px 20px;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  width: 70%;
}

.top-items {
  display: flex;
  justify-content: space-between;
}

.form-control, .form-control:active, .form-control:focus {
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  background: #1D0054;
  color: white;
}

.input-title {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 3px;
  align-items: center;
}

.input-label-img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

.title2 {
  font-size: 1.2vw;
  margin: 10px 0;
  font-weight: lighter;
}

.link-div {
  border-bottom: 2px solid #38D7D8;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 5px 0;
  margin-top: 15px;
}

.copy {
  background: -webkit-linear-gradient(99deg, #8839C2 -14.74%, #3AD9DA 77.57%, #23BCBD 127.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  margin-left: 12px;
  width: 130px;
}

.change-img {
  width: 20px;
  margin: 0 5px;
}

.gap-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gap-div img {
  height: 100%;
  object-fit: cover;
}

.input-div {
  position: relative;
  margin-top: 15px;
}

.input-div2 {
  margin-top: -40px;
}

.mobile .connect-div {
  width: 100%;
}

.mobile .title2 {
  font-size: 5.2vw;
}

#link {
  height: 25px;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.link-wrapper {
  display: flex;
  max-width: 100%;
}

.error-text {
  height: 50px;
}
</style>