<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PlayGamePart",
  data()
  {
    return{
      platform : 'desktop',
    }
  },
  mounted() {
    const window_width = window.innerWidth;
    if (window_width < 650)
      this.platform = 'mobile';
  }
})
</script>

<template>
  <div class="container" id="playGameContainer">
    <div :class="'row '+(platform === 'desktop'?'mt-5':'flex-column-reverse')">
      <div class="col-12 col-md-6 top-left-content ">
        <span class="p1 animate__animated d-block">Play a</span>
        <span class="p2 animate__animated d-block">multiplayer game in</span>
        <span class="p3 animate__animated d-block">the world of Metaverse</span>

        <div class="row">
          <div class="col-6">
            <a class="btn btn-gradient w-100" href="#">Download For Windows</a>
          </div>
          <div class="col-6">
            <a class="btn btn-gradient w-100" href="#">Download For Android</a>
          </div>
        </div>
        <div class="d-flex align-items-center gap-2 p4 animate__animated">
          <span class="world "> world</span>
<!--          <button class="btn btn-gradient download-btn">download</button>-->
<!--          <button class="btn btn-play btn-light"><img src="@/assets/images/play.svg" alt=""></button>-->
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="right-img-div">
          <img class="img-back" src="@/assets/images/game1.webp" alt="">
          <img src="@/assets/images/image_2023-11-30_13-20-45.webp" alt="" class="left-img">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
span
{
  font-family: GoodTiming;
}
.top-left-content {
  font-family: GoodTiming;
  align-self: center;
  font-size: 5vw;
  font-weight: 900;
  line-height: 1.4;
}
.right-img-div {
  border-radius: 40px;
  /* height: 100%; */
  position: relative;
  /* left: 19%; */
  aspect-ratio: 1;
  width: 100%;
  /* overflow: hidden; */
}
.right-img-div .img-back
{
  position: absolute;
  left: 15%;
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 40px;
}
.left-img {
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  left: 31%;
}
.download-btn
{
  padding-right: 25px;
  padding-left: 25px;
  margin-left: 15px;
}
.btn-play
{
  width: 45px;
  height: 45px;
  background: white;
  border-radius: 50%;
  margin-left: 15px;
}
.mobile .right-img-div .img-back {
  left: 25%;
}
.mobile .left-img {
  left: 45%;
}
.mobile .top-left-content {
  font-size: 7vw;
  margin-top: 5%;
}
</style>