<script lang="ts">
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "FrequentlyPage",
  data()
  {
    return {
      questions:[
        {q:'1. How to play DecentraGuys ?',

          a:'when you open the game, there are a few options in the main menu. The most important ones being Match and Metaverse.<br/>Metaverse mode is still under development, but you can play the game by clicking on the Match button. Joining the game requires a certain amount of energy, so be aware of that !'},
        {q:'2. Is DecentraGuys Free-To-Play ?',

          a:'DecentraGuys is a completely free to play game and playing the game costs no money. And it gives you all the competitive advantages compared to Premium members.'},

        {q:'3. How to buy DecentraGuys Character NFTs ?',

          a:'you can visit <a href="https://decentraguys.org">https://decentraguys.org</a> to see all the pricings for normal character skins.'},

        {q:'4. What are DecentraGuys Monkey Characters ?',

          a:'DecentraGuys Monkey Characters are randomised and unique NFTs that are only made for the owner of it and they are useable in the game as well.<br/>You can generate your own Monkey Character by visiting <a href="https://decentraguys.org">https://decentraguys.org</a>'},

    {q:'5. How to Add Friends in DecentraGuys Game ?',

        a:'you can visit the Social tab in the Main Menu to add friends. And you can do so by entering their username.'},

    {q:'6. How to win a DecentraGuys tournament ?',

        a:'You play three rounds of random games with 31 other people, and after that the last person standing will be crowned the winner.'},

    {q:'7. How to get Chests ?',

        a:'you can get DecentraGuys Chests by simply winning a match. In each lobby only one person can be the winner and can receive the Chest.'},

    {q:'8. What are DecentraGuys Chests ?',

        a:'DecentraGuys Chests are randomised loot chests that can have range of items in it. Energy, Tokens, Skins, limited Premiums, etc.'},

    {q:'9. What is DEGU ?',

        a:'DEGU is the DecentraGuys project\'s token. It can be used to buy in-game items and cosmetics. It can be used for Metaverse operations later on.'},

      {q:'10. How to get DEGU token ?',

          a:'you can get DEGU tokens by either playing DecentraGuys or purchasing loot boxes from DecentraGuys official website <a href="https://decentraguys.org">https://decentraguys.org</a>'}
      ]
    }
  },
  components: {Header}
})
</script>

<template>

  <Header />
  <div class="container my-5">
    <div class="row">
      <div class="col-12 col-md-6 mx-auto">
        <div class="top-title">
          Frequently asked
          <div class="color-title">question</div>
        </div>
        <div class="content">
          <div class="accordion" id="accordionExample">

            <div class="accordion-item" v-for="(item,index) in questions" v-key="index">
              <h2 class="accordion-header" :id="'faq-h-'+index">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#faq-c-'+index" aria-expanded="true" :aria-controls="'faq-c-'+index">
                  {{ item.q }}
                </button>
              </h2>
              <div :id="'faq-c-'+index" :class="'accordion-collapse collapse '+(index==0?'show':'')" :aria-labelledby="'faq-h-'+index" data-bs-parent="#accordionExample">
                <div class="accordion-body" v-html="item.a">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.container
{
  position: relative;
  z-index: 1;
}
.top-title,.color-title
{
  text-align: center;
  font-size: 3vw;
  font-family: GoodTiming;
  font-weight: 1000;
}
.content
{
  background: #1D0054;
  padding: 20px;
  margin: 15px 0;
}
.accordion-item {
  background: #1D0054;
  border: none;
  color: white;
}
.accordion-button {
  background: #1D0054!important;
  color: white!important;
  border-bottom:1px solid #D8D8D8DB;
  outline: none!important;
  box-shadow: none!important;
}
.accordion-collapse {
  border-bottom: 1px solid white;
}
.accordion-button::after {
  filter: invert();
  color: white;
}
.color-title
{
  background: -webkit-linear-gradient(99.24deg, #9400FF -13.99%, #3AD9DA 77.28%, #54F5F6 126.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>