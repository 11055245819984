const GameEnvItems = [
  {
    img:'main-menu.webp',
    title:'Main Menu',
    description:'',
  },
  {
    img:'stadium.webp',
    title:'Stadium',
    description:'Shoot that ball!',
  },
  {
    img:'chicken-run.webp',
    title:'Chicken Run',
    description:'Look out for those chickens!',
  },
  {
    img:'backrooms.webp',
    title:'Backrooms',
    description:'Avoid creatures!',
  },
  {
    img:'dumblear.webp',
    title:'Dumblear',
    description:'Get to the end alive!',
  },
  {
    img:'flag-flight.webp',
    title:'Flag Fight',
    description:'Color the flags!',
  },
  {
    img:'block-fall.webp',
    title:'Block Fall',
    description:'Death from above!',
  },
  {
    img:'zombie-run.webp',
    title:'Zombie Run',
    description:'Avoid Zombies!',
  },
  {
    img:'hot-days.webp',
    title:'Hot Days',
    description:'Dont look down there!',
  },

]
export default GameEnvItems;