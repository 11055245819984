<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "WhitePaper"
})
</script>

<template>
  <div class="page" id="whitePaperPart">
<!--    <img src="@/assets/images/pdf.webp" alt="" class="pdf-img animate__animated">-->
    <div class="container animate__animated">
      <div class="page-titles">White paper</div>
      <div class="page-contents">
        Get the withe paper to know about our project
      </div>
      <a target="_blank" href="https://decentraguys.gitbook.io/decentra-guys-white-paper/" class="btn btn-gradient mt-3">Go to WhitePaper</a>
    </div>
  </div>
</template>

<style scoped lang="css">
.page
{
  margin-top: 15%;
  margin-bottom: 5%;
  background: #231053;
  padding-top: 3%;
  padding-bottom: 3%;
  position: relative;
}
.pdf-img {
  display: block;
  position: absolute;
  width: 11%;
  //transform: translateX(-50%) translateY(-50%);
  top: -45%;
  right: 15%;
}
.mobile .pdf-img {
  right: 0;
  width: 20%;
}
</style>