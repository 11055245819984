<script lang="ts">
import {defineComponent} from 'vue'
import {Swiper, SwiperSlide} from 'swiper/vue';
import ChestBox from "@/components/ChestBox.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import {Api} from "@/api/api";
import {ChestInfoType} from "@/api/chest-info.type";
import {closeModal} from "@/helper";
import {ApiHelper} from "@/api/api-helper";

export default defineComponent({
  name: "BuyChests",
  components: {Header, Footer, ChestBox, Swiper, SwiperSlide},
  mounted()
  {
    const api = new Api();
    api.getChests().then((r)=>{
      this.chestData = r;
    })
  },
  data()
  {
    return {
      chestData: <ChestInfoType[]>[],
      imgUrl:ApiHelper.getApiUrl(false)+'images/'
    }
  },
  methods:{

    closeModal(id)
    {
      closeModal(id)
    },
  }
})
</script>

<template>

  <Header/>
  <main>
    <div class="header position-relative w-100">

      <img class="header-torus" src="@/assets/images/HeaderChests/torus.png" alt="">

      <div class="d-flex justify-content-between w-100">
        <div class="col-12 col-lg-6 d-flex position-relative flex-column wrapper-header-texts">
          <div class="d-flex align-items-center mb-7">
            <div class="hor-border"></div>
            <p class="text mb-0">
              Pay Less
              <br/>
              Get More
            </p>
          </div>
          <p class="big-header-text">
            Chose
            <br>
            your Chest
          </p>
        </div>
        <img class="col-12 col-lg-6 wrapper-chest-img-header" src="@/assets/images/HeaderChests/header-chest.png"
             alt="">
      </div>
    </div>

    <div class="d-flex flex-column px-5">
      <p class="bigest-text">
        How does it work
      </p>
      <p style="max-width: 570px;" class="regular-text">
        NFTs have been used in various industries, including art, music, gaming, sports memorabilia, virtual real
        estate, and more. They have also sparked debates around issues like copyright, ownership rights.NFTs have been
        used in various
      </p>
    </div>

    <div class="wrapper-slider p-5">
      <swiper
          :spaceBetween="20"
          slidesPerView="auto"
      >
        <SwiperSlide v-for="(data ,i) in chestData" :key="`${i}-jsfijmszf`" class="slider-together">
          <ChestBox :data="data"/>
        </SwiperSlide>
      </swiper>
    </div>


    <div class="modal fade" ref="buy-chest-modal" id="buy-chest-modal" tabindex="-1" role="dialog"
         aria-labelledby="buy-chest-modalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="buy-chest-modalLabel">Chest Purchased Successfully </h5>
            <button type="button" class="close" data-dismiss="modal" @click="closeModal('buy-chest-modal')" aria-label="Close">
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 col-12"></div>
              <div class="col-md-4 col-12">
                <img class="w-100" :src="imgUrl+'chest-1-open.webp'" id="purchased-chest-img" />
              </div>
            </div>
            <br/>
            <h3 class="text-center" id="purchased-chest-title"></h3>
            <p class="text-center">Purchased successfully. you got <span id="purchased-chest-tokens"></span> DG tokens that will be in your wallet soon.</p>
            <div class="mt-1 text-center">
              <button type="button" class="btn btn-secondary" @click="closeModal('buy-chest-modal')" data-dismiss="modal">Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer class="w-100"/>

</template>

<style scoped lang="css">

.wrapper-chest-box {
  width: 320px;
}

.header {
  height: 1100px;
}

.header-torus {
  position: absolute;
  left: 0;
}

.wrapper-header-texts {
  padding-left: 70px;
  margin-top: 120px;
}

.mb-7 {
  margin-bottom: 7rem;
}

.wrapper-chest-img-header {
  position: absolute;
  right: 0;
  top: -70px;
}

.text {
  color: #BABABA;
}

.hor-border {
  width: 2px;
  height: 120%;
  background: linear-gradient(180deg, #919191 50%, #3AD9DA 50%);
  margin-right: 25px;
}

.big-header-text {
  font-size: 130px;
  font-weight: bold;
  text-shadow: 10px 10px 10px #000;
  line-height: 170px;
}

</style>