import {Modal} from "bootstrap";

export function getImgUrl(path)
{
  path = path.replace('@/','./')
  return require(path);
}
export async function waitForPageLoad()
{
  return new Promise((resolve, reject) =>
  {
    window.addEventListener("load", function ()
    {
      setTimeout(() =>
      {
        resolve(1)
      }, 100)
    })
    // setTimeout(() =>
    // {
    //   resolve(1)
    // }, 1000)
  });
}

export async function sleep(ms)
{
  return new Promise((resolve)=>{
    setTimeout(function (){
      resolve(1);
    },ms)
  })
}

export function getNumbersFromString(s)
{
  s = String(s);
  let numbers = '';
  let dotSeen = false;
  for (let i=0;i<s.length;i++)
  {
    if (s.charAt(i)==='.' && !dotSeen)
    {
      dotSeen = true;
      numbers+='.';
      continue;
    }
    if (!isNaN(Number(s.charAt(i))))
      numbers+=s.charAt(i);
  }
  if (!numbers.length)
    return 0;
  return Number(numbers);
}

export function closeModal(id)
{
  try
  {
    document.getElementById(id)!.setAttribute('class', 'modal fade')
    document.getElementById(id)!.setAttribute('style', 'display:none')
    document.querySelector('body')!.setAttribute('class', '')
    document.querySelector('body')!.setAttribute('style', '')
    document.querySelector('.modal-backdrop')!.remove()
  }
  catch (e)
  {}
}

export function openModal(id)
{
  const e = document.getElementById(id);
  if (e)
  {
    const modal = new Modal(e);
    modal.show()
  }
}