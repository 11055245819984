import Web3 from 'web3'

declare global
{
  interface Window
  {
    ethereum?: any;
    web3?: Web3;
    injectedWeb3?: Web3;
  }
}

export class Web3Helper
{
  static getWindowWeb3()
  {
    if (window.ethereum)
      return window.ethereum;
    if (window.web3 && window.web3.currentProvider)
      return window.web3.currentProvider;
    return null;
  }

  static isWeb3Available()
  {
    return window.ethereum || window.web3;
  }

  static async getNetworkId()
  {
    let netId;
    if (window.ethereum)
    {
      netId = window.ethereum.net_version;
    }
    else if (window.web3)
    {
      // @ts-ignore
      netId = window.web3.currentProvider.net_version;
    }
    if (!netId)
    {
      // @ts-ignore
      const requestFunc = window.ethereum ? window.ethereum.request : (window.web3 ? window.web3.currentProvider.request : null);
      if (requestFunc)
      {
        try
        {
          const data = {
            method: 'net_version',
            jsonrpc: '2.0',
            id: new Date().getTime()
          };
          netId = await requestFunc(data);
          return netId;
        }
        catch (e)
        {
        }
      }
    }
    return null;
  }

  static async isWeb3Injected()
  {
    if (!window.injectedWeb3)
      this.injectWeb3();
    if (!window.injectedWeb3)
      return false;
    try
    {
      const accounts = await window.injectedWeb3.eth.getAccounts();
      return !(!accounts || !accounts.length);

    }
    catch (e)
    {
      console.log(e);
      return false;
    }
  }

  static async getAccounts()
  {
    if (!window.injectedWeb3)
      this.injectWeb3();
    if (!window.injectedWeb3)
      return false;
    try
    {
      const accounts = await window.injectedWeb3.eth.getAccounts();
      if (accounts && accounts.length)
        return accounts;

    }
    catch (e)
    {
      console.log(e);
    }
    return [];
  }

  static async enableWeb3()
  {
    try
    {
      // @ts-ignore
      if (window.ethereum)
      {
        await window.ethereum.enable()
      }
      // @ts-ignore
      else if (window.web3)
      {
        // @ts-ignore
        await window.web3.currentProvider.enable()
      }
      return true;
    }
    catch (e)
    {
      console.log(e)
    }
    return false;
  }

  static async connectToBNB()
  {
    window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
          name: 'Binance Coin',
          symbol: 'BNB',
          decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com']
      }]
    })
      .catch((error) =>
      {
        console.log(error)
      })
  }

  static async getNativeBalance(web3, address)
  {
    return await web3.eth.getBalance(address);
  }

  static async connectToPolygon()
  {
    if (window.ethereum)
    {
      try
      {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
              name: 'POL',
              symbol: 'POL',
              decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com/']
          }]
        });
        console.log('Connected to Polygon');
      }
      catch (error)
      {
        console.error('Error connecting to Polygon:', error);
      }
    }
    else
    {
      console.error('MetaMask is not installed');
    }
  }


  static injectWeb3()
  {
    // @ts-ignore
    if (window.ethereum)
    {
      window.injectedWeb3 = new Web3(window.ethereum)
      return window.injectedWeb3
    }
    // @ts-ignore
    else if (window.web3)
    {
      window.injectedWeb3 = new Web3(window.web3.currentProvider)
      return window.injectedWeb3
    }
    return false
  }
}
